import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import RoomIcon from "@mui/icons-material/Room";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";
import MoneyIcon from "@mui/icons-material/Money";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import JobDetails from "../JobDetails/JobDetails";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import GavelIcon from "@mui/icons-material/Gavel";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import GetResponse from "@mui/icons-material/Feedback";
import Swal from "sweetalert2";
import AppContext from "../AppContext";

import Section1 from "./Section1"
import { Section2 } from "./Section2";
import Pagination from "../Common/Pagination";
import { RotatingLines } from 'react-loader-spinner';

import { IconButton, Tooltip } from "@mui/material";
import BoltOutlinedIcon from '@mui/icons-material/BoltOutlined';
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import VisibilityOffRoundedIcon from '@mui/icons-material/VisibilityOffRounded';

var moment = require('moment');
export const JobTile = ({ title, type, isStared,clientRespondedOn, ...rest }) => {
  const navigate = useNavigate();
  const myContext = useContext(AppContext);
  const [role, setRole] = useState(localStorage.getItem("role"));
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showToogle, setShowToogle] = useState(false);
  
  const [jobs, setJobs] = useState([]);
  const [usersJson, setUsersJson] = useState({});
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [isJobUpdating, setIsJobUpdating] = useState(false);
  const [lastToogleJobId, setLastToogleJobId] = useState(null);
  const [credits, setCredits] = useState(localStorage.getItem("credit_limit"));
  const [totals, setTotals] = useState({
    totalPages: 0,
    totalJobs: 0,
    totalAcceptedJobs: 0,
    totalRejectedJobs: 0,
    totalApprovedJobs: 0,
    totalBidJobs: 0,
    monirRefreshJobs: 0,
  });
  const [filterType, setFilterType] = useState("All");
  const [sortingType, setSortingType] = useState("DESC");
  const [sortBy, setSortBy] = useState("time");
  

  const [keyword, setKeyword] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [score, setScore] = useState([]);

  const current = new Date();
  const currentDate = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

  const [limit, setLimit] = useState(50);

  const [loadingChatGptResponse, setLoadingChatGptResponse] = useState({});

  useEffect(() => {
    getUsersList();
  }, []);

  useEffect(() => {
    if (type) {
      getJobs();
    } else {
      getAssignedJobs();
    }
  }, [searchText, filterType, sortingType, sortBy, keyword, limit, pageCount,subcategory,score]);

  const getJobs = () => {
    if (navigator.onLine) {
      if (isLoading) return;
      setIsLoading(true);
      let staredFilter = '';
      if (isStared == '1') {
        staredFilter = "&is_stared=1" ;
      }
      let clientResponsFilter = '';
      if(clientRespondedOn =='1'){
        clientResponsFilter = "&client_responded_on=1"
      }
      fetch(
        process.env.REACT_APP_API_URL +
          "jobs?type=" +
          type +
          "&page=" +
          pageCount +
          "&limit=" +
          limit +
          "&searchText=" +
          searchText +
          "&categoryFilter=" +
          filterType +
          "&sortingValue=" +
          sortBy +
          "&viewBy=" +
          sortingType +
          "&keyword=" +
          keyword+
          "&score=" +
          score +
          "&subcategory=" +
          encodeURIComponent(subcategory) +
          staredFilter +
          clientResponsFilter,
        {
          method: "GET",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      ).then((data) =>
        data
          .json()
          .then((result) => {
            if (result.success) {
              setJobs(result.data)
              setCredits(result.credit)
              setTotals({
                totalPages: result.total_pages,
                totalJobs: result.total_jobs,
                totalAcceptedJobs: result.total_accepted,
                totalRejectedJobs: result.total_rejected,
                totalApprovedJobs: result.total_approved,
                totalBidJobs: result.total_bid,
                monirRefreshJobs: result.monirRefreshJobs,
              });
              setIsLoading(false);

            } else {
              setIsLoading(false);
              if (result.errorCode === 401) {
                localStorage.clear();
                navigate("/login");
              }
            }
          })
          .catch((err) => {
            console.log(err);
          })
      );
    } else {
      Swal.fire("No Network Connection.");
    }
  };

  const getAssignedJobs = async () => {
    if (navigator.onLine) {
      if (isLoading) return;
      setIsLoading(true);

      fetch(
        process.env.REACT_APP_API_URL +
          "jobs/assignjob/list?page=" +
          pageCount +
          "&searchText=" +
          searchText,
        {
          method: "GET",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
        }
      ).then((data) =>
        data
          .json()
          .then((result) => {
            if (result.success) {
              setTimeout(() => {
                setJobs(result.data);
                setCredits([result.credit]);
                setTotals({
                  totalPages: result.total_pages,
                  totalJobs: result.total_jobs,
                  totalAcceptedJobs: result.total_accepted,
                  totalRejectedJobs: result.total_rejected,
                  totalApprovedJobs: result.total_approved,
                  totalBidJobs: result.total_bid,
                  monirRefreshJobs: result.monirRefreshJobs,
                });
                setIsLoading(false);
              }, 300);
            } else {
              setIsLoading(false);
            }
          })
          .catch((err) => {
            console.log(err);
          })
      );
    } else {
      Swal.fire("No Network Connection.");
    }
  };

  const handleClose = () => setShow(false);

  const hideJobDetails = () => {
    if (lastToogleJobId !== null) {
      setJobs(
        jobs.map((job) =>
          job.id === lastToogleJobId
            ? { ...job, isToogleActive: 0 }
            : { ...job, isToogleActive: 0 }
        )
      );
    }
  };

  const hideLastJobDetails = () => {
    setJobs(
      jobs.map((job) =>
        job.id === lastToogleJobId
          ? { ...job, isToogleActive: 0 }
          : { ...job, isToogleActive: 0 }
      )
    );
  };

  const handleShow = useCallback(async (jobId) => {
    
    if (showToogle === false) {
      await hideJobDetails();
      setLastToogleJobId(jobId);
      setShowToogle(true);
      window.location.href = "#jobId" + jobId;
      setJobs(
        jobs.map((job) =>
          job.id === jobId
            ? { ...job, isToogleActive: 1 }
            : { ...job, isToogleActive: 0 }
        )
      );
    } else {
      setShowToogle(false);
      if (lastToogleJobId === jobId) {
        setJobs(
          jobs.map((job) =>
            job.id === jobId
              ? { ...job, isToogleActive: 0 }
              : { ...job, isToogleActive: 0 }
          )
        );
      } else {
        await hideLastJobDetails();
        setLastToogleJobId(jobId);
        setShowToogle(true);
        window.location.href = "#jobId" + jobId;
        setJobs(
          jobs.map((job) =>
            job.id === jobId
              ? { ...job, isToogleActive: 1 }
              : { ...job, isToogleActive: 0 }
          )
        );
      }
    }
  }, [showToogle, lastToogleJobId, jobs]);


  useEffect(() => {
    if (isJobUpdating === true) {
      myContext.on("viewUpdateJobs", async (result) => {
        setIsJobUpdating(false);
        setTotals({
          totalPages: totals.totalPages,
          totalJobs: totals.totalJobs - 1,
          totalAcceptedJobs: result.accepted,
          totalRejectedJobs: result.rejected,
          totalApprovedJobs: result.approved,
          totalBidJobs: result.bid,
        });
      });
    }
  }, [isJobUpdating]);

  const handleUpdateJob = useCallback((jobId, jobType, upworkJobId, budget = null, is_checked = null) => {
    let isChecked = false;
    let is_stared = '0';
    if(is_checked !== null) {
      isChecked = is_checked.target.checked;
      if(is_checked.target.checked === true){
        is_stared = '1';
      }
    }
    setIsJobUpdating(true);
    if (jobType === 1 && budget > 0 && budget <= 500) {
      Swal.fire({
        title: "Are you sure?",
        text: "The budget of this project is just $" + budget,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          if(is_stared == '1' && type == '1'){
          }
          else if(is_stared == '0' && type == '1'){
          }
          else{
            // document.getElementById("jobId" + jobId).remove();
          }
          setCredits(parseInt(credits) - 2);
          myContext.emit(
            "updateJobStatus",
            JSON.stringify({
              jobId,
              jobType,
              upworkJobId,
              token: localStorage.getItem("token"),
              is_stared
            })
          );
        } else {
          handleClose();
          if(is_checked !== null){
            if(is_stared == '1'){
              document.getElementById("starjobId" + jobId).checked = false;
            }
            else{
              document.getElementById("starjobId" + jobId).checked = true;
            }
          }
        }
      });
    } else if (jobType === 1) {
      setCredits(parseInt(credits) - 2);
      if(is_stared == '1' && type == '1'){
      }
      else if(is_stared == '0' && type == '1'){
      }
      else{        
        // document.getElementById("jobId" + jobId).remove();
        // setJobs(jobs.filter((v, i) => v.id !== jobId));
      }
      myContext.emit(
        "updateJobStatus",
        JSON.stringify({ jobId, jobType,upworkJobId, token: localStorage.getItem("token"), is_stared })
      );
      
    } else if (jobType === 2) {
      setCredits(parseInt(credits) + 1);
         
      myContext.emit(
        "updateJobStatus",
        JSON.stringify({ jobId, jobType,upworkJobId, token: localStorage.getItem("token"), is_stared })
      );


    } else if (jobType === 4) {
      Swal.fire({
        title: "Are you sure you want to place this bid?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!!",
      }).then((result) => {
        if (result.value) {
          myContext.emit(
            "updateJobStatus",
            JSON.stringify({
              jobId,
              jobType,
              upworkJobId,
              token: localStorage.getItem("token"),
            })
          );
        }
      });
    } else {      
      myContext.emit(
        "updateJobStatus",
        JSON.stringify({ jobId, jobType,upworkJobId, token: localStorage.getItem("token") })
      );
    }
    document.getElementById("jobId" + jobId).style.display = "none";

    // find if all the jobs removed from page and get next jobs
    let divs = document.querySelectorAll('.sectionClass');
    var divsArray = [].slice.call(divs);
    var displayShow = divsArray.filter(function(el) {
      return getComputedStyle(el).display !== "none"
    });
    var numberOfVisibleDivs = displayShow.length;
    if (numberOfVisibleDivs === 0) {
      setPageCount(pageCount)
      getJobs()
    }
    // end
  }, [credits, type, myContext, setIsJobUpdating]);

  const getUsersList = () => {
    if (navigator.onLine) {
      fetch(process.env.REACT_APP_API_URL + "userlist/", {
        method: "GET",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
      }).then((data) =>
        data
          .json()
          .then((result) => {
            if (result.success) {
              setUsersJson(result.data);
            } else {
              console.log("eeelese",result);
            }
          })
          .catch((err) => {
            console.log(err);
          })
      );
    } else {
      Swal.fire("No Network Connection.");
    }
  };

  const handleJobAssignment = (jobId) => {
    Swal.fire({
      title: "Who will be commented on it?",
      input: "select",
      inputOptions: usersJson,
      inputPlaceholder: "Please select user",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value != "") {
            resolve();
          } else {
            resolve("You need to select a user");
          }
        });
      },
    }).then(function (result) {
      if (result.value) {
        var user_id = result.value;
        assignJob(jobId, user_id);
      }
    });
  };

  const assignJob = (job_id, user_id) => {
    if (navigator.onLine) {
      if (isLoading) return;
      setIsLoading(true);
      return fetch(process.env.REACT_APP_API_URL + "jobs/assignjob/" + job_id, {
        method: "PUT",
        headers: {
          accept: "*/*",
          "Content-Type": "application/json",
          token: localStorage.getItem("token"),
        },
        body: JSON.stringify({ user_id }),
      })
        .then((data) => data.json())
        .then((result) => {
          if (result.success) {
            var user_name = usersJson[user_id];
            setJobs(
              jobs.map((job) =>
                job.id === job_id
                  ? { ...job, user_assigned: user_name }
                  : { ...job }
              )
            );
            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        });
    } else {
      Swal.fire("No Network Connection.");
    }
  };

  const handleJobRefresh = () => {
    document.getElementById("loading_img").classList.remove('d-none');
    document.getElementById("refresh_icon").classList.add('d-none');
    
    fetch(process.env.REACT_APP_API_URL + "updateupworkjobs?type=" + type, {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    }).then((data) =>
      data.json().then((result) => {
        if (result.success) {

          myContext.on("refreshJobs", async (resp) => {
            setTotals({ ...totals, totalJobs: totals.totalJobs - 1 });
            document.getElementById("jobId" + resp).remove();
          });
          document.getElementById("loading_img").classList.add('d-none');
          document.getElementById("refresh_icon").classList.remove('d-none');
        } else {
          console.log(result);
        }
      })
      .catch((err) => {
        console.log(err);
      })
    );
  };

  const handleJobRefreshMinor = () => {
    document.getElementById("minor_loading_img").classList.remove('d-none');
    document.getElementById("minor_refresh_icon").classList.add('d-none');
    
    fetch(process.env.REACT_APP_API_URL + "updateupworkjobsMinor?type=" + type, {
      method: "GET",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
    }).then((data) =>
      data
        .json()
        .then((result) => {
          if (result.success) {

            myContext.on("refreshJobs", async (resp) => {
              setTotals({ ...totals, totalJobs: totals.totalJobs - 1 });
              document.getElementById("jobId" + resp).remove();
            });            
            document.getElementById("minor_loading_img").classList.add('d-none');
            document.getElementById("minor_refresh_icon").classList.remove('d-none');
          } else {
            console.log(result);
          }
        })
        .catch((err) => {
          console.log(err);
        })
    );
  };

  const handleClientResponse = (jobId,e) => {
    let is_responded = '0';
      if(e.target.checked === true){
        is_responded = '1';
      }
    Swal.fire({
      title: "Are you sure?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes!!",
    }).then((result) => {
      if (result.value) { 
        
        return fetch(process.env.REACT_APP_API_URL + "jobs/clientresponse/" + jobId, {
          method: "PUT",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
          body:JSON.stringify({"is_responded":is_responded})
        })
        .then((data) => data.json())
        .then((result) => {
          if (result.success) {
            if(is_responded == '1'){
              setJobs(
                jobs.map((job) =>
                  job.id === jobId
                    ? { ...job, client_responded_on: currentDate }
                    : { ...job }
                ) );
              }
              else{
                if(clientRespondedOn == "1"){
                  document.getElementById("jobId" + jobId).remove();
                }
                setJobs(                  
                  jobs.map((job) =>
                    job.id === jobId
                      ? { ...job, client_responded_on: null }
                      : { ...job }
                  )
                );
              }            
          }
        });
      }
    });

  }

  const handleViewedByClient = (jobId) => {
  
    const jobToUpdate = jobs.find((job) => job.id === jobId);
  
    if (!jobToUpdate) {
      console.error("Job not found");
      return;
    }
    
    const newViewedStatus = jobToUpdate.is_viewed_by_client === '1' ? '0' : '1';
  
    // Update the local state
    setJobs(prevJobs =>
      prevJobs.map((job) =>
        job.id === jobId
          ? { ...job, is_viewed_by_client: newViewedStatus }
          : job
      )
    );
   
    fetch(`${process.env.REACT_APP_API_URL}jobs/isViewedByclient/${jobId}`, {
      method: "PUT",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
      body: JSON.stringify({ is_viewed_by_client: newViewedStatus })
    })
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error('Invalid response format');
        }
        return response.json();
      })
      .then(result => {
        if (!result.success) {
          console.error("Failed to update viewed status on the server.");
          Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: result.message || 'Failed to update viewed status. Please try again.',
          });
         
          setJobs(prevJobs =>
            prevJobs.map((job) =>
              job.id === jobId
                ? { ...job, is_viewed_by_client: jobToUpdate.is_viewed_by_client }
                : job
            )
          );
        }
      })
      .catch(error => {
        console.error("Error updating viewed status:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while updating the viewed status. Please try again later.',
        });
        
        setJobs(prevJobs =>
          prevJobs.map((job) =>
            job.id === jobId
              ? { ...job, is_viewed_by_client: jobToUpdate.is_viewed_by_client }
              : job
          )
        );
      });
  };

  const handleBoostedStatus = (jobId) => {
  
    const jobToUpdate = jobs.find((job) => job.id === jobId);
    if (!jobToUpdate) {
      console.error("Job not found");
      return;
    }
    
    const newBoostedStatus = jobToUpdate.is_job_boosted === '1' ? '0' : '1';
    setJobs(prevJobs =>
      prevJobs.map((job) =>
        job.id === jobId
          ? { ...job, is_job_boosted: newBoostedStatus }
          : job
      )
    );
   
    fetch(`${process.env.REACT_APP_API_URL}jobs/isBoosted/${jobId}`, {
      method: "PUT",
      headers: {
        accept: "*/*",
        "Content-Type": "application/json",
        token: localStorage.getItem("token"),
      },
      body: JSON.stringify({ is_job_boosted: newBoostedStatus })
    })
      .then(response => {
        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
          throw new Error('Invalid response format');
        }
        return response.json();
      })
      .then(result => {
        if (!result.success) {
          console.error("Failed to update boosted status on the server.");
          Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: result.message || 'Failed to update boosted status. Please try again.',
          });
          
          setJobs(prevJobs =>
            prevJobs.map((job) =>
              job.id === jobId
                ? { ...job, is_job_boosted: jobToUpdate.is_job_boosted }
                : job
            )
          );
        }
      })
      .catch(error => {
        console.error("Error updating boosted status:", error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'An error occurred while updating the boosted status. Please try again later.',
        });
        
        setJobs(prevJobs =>
          prevJobs.map((job) =>
            job.id === jobId
              ? { ...job, is_job_boosted: jobToUpdate.is_job_boosted }
              : job
          )
        );
      });
  };

  const getTimeSince = (created_date) => {
    let new_date = moment(created_date, 'YYYY-MM-DD HH:mm:ss').add({ hours: 5, minutes: 30 });

    if (typeof new_date !== 'object') {
      new_date = new Date(new_date);
    }
  
    var seconds = Math.floor((new Date() - new_date) / 1000);
    var intervalType;
  
    var interval = Math.floor(seconds / 31536000);

    if (interval >= 1) {
      intervalType = 'year';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'month';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'day';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }
      if (interval > 1 || interval === 0) {
      intervalType += 's';
    }  
    return interval + ' ' + intervalType;
  }

  const handleJobDelete = () => {
    const filterValue = document.getElementById("remove_jobs").value;
    if(filterValue != ''){
      Swal.fire({
        title: "Are you sure?",
        // text: "The budget of this project is just $" + budget,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes!",
      }).then((result) => {
        if (result.value) {
          fetch(process.env.REACT_APP_API_URL + "jobs/removeJobs/" + filterValue, {
            method: "PUT",
            headers: {
              accept: "*/*",
              "Content-Type": "application/json",
              token: localStorage.getItem("token"),
            },
          }).then((data) =>
            data.json().then(async (result) => {
                if (result.success) {
                  await setTotals({
                    totalPages: 0,
                    totalJobs: 0,
                    totalAcceptedJobs: 0,
                    totalRejectedJobs: 0,
                    totalApprovedJobs: 0,
                    totalBidJobs: 0,
                    monirRefreshJobs: 0,
                  });
                  setTimeout(() => {
                    getJobs();
                    document.getElementById("remove_jobs").value='';
                  }, 100);
                  
                } 
              })
              .catch((err) => {
                console.log("err==",err);
              })
          );
        }
      })
    }
    else{
      Swal.fire("Please select an option!")
    }
  }

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setPageCount(1)
  }
   

  const listRef = useRef(null);
  const [touchOrigin, setTouchOrigin] = useState(null);
  const [isSwiping, setIsSwiping] = useState(false);
  const [currentTask, setCurrentTask] = useState(null);
  const [jobId, setJobId] = useState(null);
  const [jobJobId, setJobJobId] = useState(null);
  const swipeMargin = 100;
  const originalClassList = useRef(null);

  const startSwipe = (evt, jobId, jobJobId) => {    
    let target = evt.target;  
    // Traverse up the DOM tree until we find the parent task element
    while (target && !target.classList.contains('task')) {
      target = target.parentNode;
    }
  
    if (target) {
      if (evt.type === 'mousedown') {
        setTouchOrigin(evt.screenX);
      } else if (evt.type === 'touchstart') {
        setTouchOrigin(evt.touches[0].clientX);
      }
  
      setCurrentTask(target);
      setIsSwiping(true);
      setJobId(jobId);
      setJobJobId(jobJobId);

      originalClassList.current = target.classList.value;            
    }
  };

  const endSwipe = () => {
    if (currentTask) {
      if (currentTask.classList.contains("completing")) {
        currentTask.remove();
      } else if (currentTask.classList.contains("deleting")) {
        currentTask.remove();
      }
    }

    setTouchOrigin(null);
    setIsSwiping(false);
    if (currentTask) {
      currentTask.style.margin = 0;
      setCurrentTask(null);
    }

    
    // Check if list is empty after a task is completed
    if (listRef.current) {
      if (listRef.current.children.length === 0) {
        listRef.current.style.display = 'none'; // Hide the task list
      }
    }
  };

  const detectMouse = (evt) => {
    const currentMousePosition = evt.screenX;
    handleSwipe(currentMousePosition);
  };

  const detectTouch = (evt) => {
    const currentTouchPosition = evt.touches[0].clientX;
    handleSwipe(currentTouchPosition);
  };

  const handleSwipe = (currentPosition) => {
    const swipeDifference = Math.abs(touchOrigin - currentPosition);
  
    if (isSwiping && currentTask && swipeDifference > swipeMargin) {
      currentTask.classList.add("ttt");

      if (swipeDifference - swipeMargin <= swipeMargin) {
        currentTask.classList.remove("completing");
        currentTask.classList.remove("deleting");
        currentTask.style.margin = 0;
      } else if (touchOrigin > currentPosition) {
        currentTask.classList.remove("deleting");
        currentTask.classList.add("completing");
        currentTask.style.marginLeft = -swipeDifference + "px";

        handleUpdateJob(jobId, 1, jobJobId)        

      } else if (touchOrigin < currentPosition && !currentTask.classList.contains("completed")) {
        currentTask.classList.remove("completing");
        currentTask.classList.add("deleting");
        currentTask.style.marginLeft = swipeDifference + "px";

        handleUpdateJob(jobId, 2, jobJobId)
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', endSwipe);
    document.addEventListener('touchend', endSwipe);
    document.addEventListener('mousemove', detectMouse);
    document.addEventListener('touchmove', detectTouch);

    return () => {
      document.removeEventListener('mouseup', endSwipe);
      document.removeEventListener('touchend', endSwipe);
      document.removeEventListener('mousemove', detectMouse);
      document.removeEventListener('touchmove', detectTouch);
    };
  }, [endSwipe, detectMouse, detectTouch]);

  const handleGetResponse = (jobTitle, jobSnippet, job_id) => {
    console.log("kkkk=====>")
      setLoadingChatGptResponse((prevState) => ({
        ...prevState,
        [job_id]: true,
      }));
      // return

      if (navigator.onLine) {
        fetch(process.env.REACT_APP_API_URL + "jobs/getChatGPTResponse", {
          method: "POST",
          headers: {
            accept: "*/*",
            "Content-Type": "application/json",
            token: localStorage.getItem("token"),
          },
          body :JSON.stringify({title:jobTitle, snippet:jobSnippet, job_id:job_id})
        }).then((data) => data.json().then((result) => {
          console.log("result.data=====>",result.data)
          setLoadingChatGptResponse((prevState) => ({
            ...prevState,
            [job_id]: false,
          }));
              
              if (result.success) {
                
                setJobs(
                  jobs.map((job) =>
                    job.id === parseInt(job_id)
                      ? { ...job, job_general_comment: result.data }
                      : { ...job }
                  )
                );

              } else {
                console.log("handleGetResponse else",result);
              }
            })
            .catch((err) => {
              
              setLoadingChatGptResponse((prevState) => ({
                ...prevState,
                [job_id]: false,
              }));
          
              console.log(err);
            })
        );
      } else {
        
          setLoadingChatGptResponse((prevState) => ({
            ...prevState,
            [job_id]: false,
          }));
          
        Swal.fire("No Network Connection.");
      }

  }
  return (
    <>
      <section>
        <Section1 
          title={title} 
          type={type} 
          totals={totals} 
          limit={limit} 
          searchText={searchText} 
          setSearchText={setSearchText} 
          handleJobDelete={handleJobDelete} 
          handleJobRefresh={handleJobRefresh} 
          handleJobRefreshMinor={handleJobRefreshMinor} 
          handleLimitChange={handleLimitChange} 
          setSubcategory={setSubcategory}
          setScore={setScore}
        />
        <Section2  
          type={type} 
          totals={totals} 
          sortBy={sortBy} 
          setSortBy={setSortBy} 
          sortingType={sortingType} 
          setSortingType={setSortingType} 
          filterType={filterType} 
          setFilterType={setFilterType} 
          keyword={keyword} 
          setKeyword={setKeyword}
        />        
        <div className="bg-gray">       
          { isLoading ? 
              <div className={ isLoading ? "d-flex justify-content-center my-5":"d-flex justify-content-center"}>
                <RotatingLines strokeColor="#b41309" strokeWidth="2" animationDuration="0.75" width="75" visible={isLoading} />
              </div>
          : 
          <>
            <div className="jobs_list" ref={listRef}>
              {jobs.length > 0 ? (
                jobs.map((job, index) => (
                    <section key={index} id={"jobId" + job.id} className="sectionClass task" onTouchStart={(e) => startSwipe(e, job.id, job.job_id)}>
                      <div className="job-card-container position-relative">
                        {loadingChatGptResponse[job.id] && (
                          <div className="loading-overlay">
                            <div className="loader">Loading...</div>
                          </div>
                        )}
                      
                      <>
                        <div className="job-card">
                          <div className="row">
                            <div className="col-md-10" style={{ cursor: "pointer" }} onClick={() => handleShow(job.id)}>

                              <div className="d-flex align-items-center">
                                <div className="job-title">
                                  <h4>{job.title}</h4>
                                </div>
                                <div className="job-points">
                                  <p className="mb-0">{job.score === 0 ? 0 : (job.score === null ? 'Null' : (job.score || 0)) }</p>
                                </div>
                              </div>

                              <div className="job-details">
                                <ul>
                                  <li> <RoomIcon /><span>{job.country}</span> </li>

                                  {job.payment_verification_status === "" ? (
                                  <li><CloseIcon className="text-danger" /> <span className="verify-none">NOT VERIFIED</span> </li>
                                  ) :  (<li><CheckIcon className="text-success" /> <span className="verify-none">VERIFIED</span></li> )}

                                  {job.client_tot_spent ? (
                                    <li><MoneyIcon /><span title="Total Spent">{"TS: " + job.client_tot_spent}</span></li>
                                  ) : ("")}

                                  { job.client_contract_date ?
                                    <li> <CalendarMonthIcon /><span title="Member Since"> MS: {job.client_contract_date}{" "} </span></li>
                                    : "" }

                                  { 
                                    job.date_created != ''?
                                    <li><ScheduleIcon /> <span>{getTimeSince(job.date_created)+" ago"} </span></li>
                                    : ""
                                  }
                                  <li><AttachMoneyIcon /> <span title="Budget"> B: {job.budget ? job.budget : 0}{" "}</span></li>

                                  {job.op_tot_intv ? (
                                    <li><span title="Total Interviewing">{"TI: " + job.op_tot_intv}</span></li>
                                  ) : ( "")}

                                  <li><span title="Total Proposals"> {"TP: " + job.op_tot_cand} </span></li>

                                  {job.avg_spent ? (
                                    <li><span title="Avg Spent"> {"Avg Spent: $" + job.avg_spent.toFixed()} </span></li>
                                  ) : ( "" )}

                                  {job.hire_percentage ? (
                                    <li><span title="Hire %"> {"Hire %: " + job.hire_percentage.toFixed(2)} </span></li>
                                  ) : ( "" )}

                                </ul>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="job-actions" id={"actionsJobId" + job.id} >
                                {type === "3" ? (
                                  <>
                                    <Tooltip
                                        title={job.is_job_boosted === "1" ? "Job Boosted" : "Click here to boost"}
                                        size="small"
                                        className="cursor-pointer"
                                        sx={{backgroundColor:'black'}}
                                    >
                                        <IconButton onClick={(e) => handleBoostedStatus(job.id)} className={job.is_job_boosted === "1" ? 'icon-button-active-boosted' : 'icon-button-default'}>
                                            {job.is_job_boosted === "1" ? <BoltOutlinedIcon /> : <BoltOutlinedIcon />}
                                        </IconButton>
                                    </Tooltip>
                                    <a onClick={() => handleGetResponse(job.title, job.snippet, job.id)} className="job-icon comment logo" title="Get Response">
                                      {/* <GetResponse />{" "} */}
                                      <img src="assets/img/chatgpt-icon.png" alt="Logo" style={{maxHeight: '25px'}} />
                                    </a>
                                    <a onClick={() => handleUpdateJob(job.id, 4,job.job_id)} className="job-icon text-success" title="Bid" >
                                      <GavelIcon />
                                    </a>
                                  </>
                                ) : ( "" )}
                                
                                {job.job_attachement ? (
                                  <a href={"https://upwork.com/jobs/" + job.job_id} className="job-icon attachment" target="_Blank">
                                    <AttachFileIcon />{" "}
                                  </a>
                                ) : ( "" )}

                                {job.job_general_comment ? (
                                  <a className="job-icon comment" id={"commentJobId" + job.id} title={job.job_general_comment.split("&apos;").join("'")}>
                                    <i className="fa fa-comment fa-lg"></i>{" "}
                                  </a>
                                ) : ( "" )}                          

                                {type === "0" || type === "JBK" ? (
                                  <>                              
                                    {role === "Admin" ? (
                                      <a onClick={() => handleJobAssignment(job.id)} className="job-icon comment" title="Assign For Comments" >
                                        <AssignmentReturnIcon />{" "}
                                      </a>
                                    ) : ( "" )}                             
                                    
                                    <input className="star" type="checkbox" title="Mark Important"  onClick={(e) => handleUpdateJob(job.id, 1,job.job_id, job.budget,e)} id={"starjobId" + job.id} defaultChecked ={ job.is_stared === '1' ? true : false}/>
                                    
                                    {
                                      //credits > 1 ?
                                      <a onClick={ () => handleUpdateJob(job.id, 1,job.job_id, job.budget) }  title="Accept" className="job-icon text-success" >
                                        <ThumbUpIcon />
                                      </a>
                                      //: ''
                                    }
                                  </>
                                ) : ( "" )}
                                {type ? (
                                  ""
                                ) : (
                                  <>
                                    {credits > 1 ? (
                                      <a onClick={() => handleUpdateJob(job.id, 1,job.job_id, job.budget) }  title="Accept" className="job-icon text-success" >
                                        <ThumbUpIcon />
                                      </a>
                                    ) : ( "" )}
                                    <a onClick={() => handleUpdateJob(job.id, 2,job.job_id)} className="job-icon reject" title="Reject" >
                                      <ThumbDownIcon />{" "}
                                    </a>
                                  </>
                                )}

                                {type === "1" ? (
                                  <> 
                                    <a onClick={() => handleGetResponse(job.title, job.snippet, job.id)} className="job-icon comment logo" title="Get Response" >
                                      {/* <GetResponse />{" "} */}
                                      <img src="assets/img/chatgpt-icon.png" alt="Logo" style={{maxHeight: '25px'}} />
                                    </a>

                                    {role === "Admin" ? (
                                      <a onClick={() => handleJobAssignment(job.id)} className="job-icon comment" title="Assign For Comments" style={{marginLeft: '0px'}}>
                                        <AssignmentReturnIcon />{" "}
                                      </a>
                                    ) : ( "" )}
                                    {
                                      <input className="star" type="checkbox" title="Mark Important"  onClick={(e) => handleUpdateJob(job.id, 1,job.job_id, job.budget,e)} id={"starjobId" + job.id} defaultChecked ={ job.is_stared === '1' ? true : false}/>
                                    }
                                    {role === "Admin" ? (
                                      <a onClick={() => handleUpdateJob(job.id, 3,job.job_id)} className="job-icon text-success"
                                        title="Approve" >
                                        <ThumbUpIcon />{" "}
                                      </a>
                                    ) : ( "" )}
                                    <a onClick={() => handleUpdateJob(job.id, 2,job.job_id)} className="job-icon reject" title="Reject" >
                                      <ThumbDownIcon />{" "}
                                    </a>
                                  </>
                                ) : ( "" )}
                                                        

                                {type === "2" && role === "Admin" ? (
                                  <>
                                  <input className="star" type="checkbox" title="Mark Important"  onClick={(e) => handleUpdateJob(job.id, 1,job.job_id, job.budget,e)} id={"starjobId" + job.id} defaultChecked ={ job.is_stared === '1' ? true : false}/>
                                  
                                  <a onClick={() => handleUpdateJob(job.id, 1,job.job_id)} className="job-icon accept text-success" title="Accept" >
                                    <ThumbUpIcon />
                                  </a>
                                  </>
                                ) : ( "" )}

                                {type === "3" || type === "0" || type === "JBK" ? (
                                  <>
                                    <a onClick={() => handleUpdateJob(job.id, 2,job.job_id)} className="job-icon reject" title="Reject" >
                                      <ThumbDownIcon />{" "}
                                    </a>
                                  </>
                                ) : ( "" )}

                                {type === "4" || clientRespondedOn === '1' ? (
                                  <>
                                    <Tooltip
                                        title={job.is_viewed_by_client === "1" ? "Viewed by client" : "Click here to mark as viewed"}
                                        size="small"
                                        className="job-viewed-icon cursor-pointer"
                                    >
                                        <IconButton onClick={(e) => handleViewedByClient(job.id)} className={job.is_viewed_by_client === "1" ? 'icon-button-active-viewed' : 'icon-button-default'} style={{margin: '0px 5px 0px 0px'}}>
                                          {job.is_viewed_by_client === "1" ? <VisibilityRoundedIcon fontSize="small" /> : <VisibilityOffRoundedIcon fontSize="small" />}
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip
                                        title={job.is_job_boosted === "1" ? "Job Boosted" : "Click here to boost"}
                                        size="small"
                                        className="cursor-pointer me-1"
                                        sx={{backgroundColor:'black'}}
                                    >
                                        <IconButton onClick={(e) => handleBoostedStatus(job.id)} className={job.is_job_boosted === "1" ? 'icon-button-active-boosted' : 'icon-button-default'}>
                                            {job.is_job_boosted === "1" ? <BoltOutlinedIcon /> : <BoltOutlinedIcon />}
                                        </IconButton>
                                    </Tooltip>
                                    <label class="switch">
                                      <input type="checkbox" onClick={(e) => handleClientResponse(job.id,e) } checked={job.client_responded_on == null ? false : true}/>
                                      <span class="slider round"></span>
                                    </label>
                                  </>
                                ) : ( "" ) }
                              </div>
                              <div className="job-actions">
                                {(type === "0" || type === "JBK") && job.user_assigned ? (
                                  <span className="username_cls">{"Assigned To: " + job.user_assigned}</span>
                                ) : ("")}

                                {type === "1" && job.user_name ? (
                                  <span className="username_cls">{"Accepted By: " + job.user_name}</span>
                                ) : ("")}
                                
                                {type === "3" && job.user_name ? (
                                  <span className="username_cls">{"Accepted By: " + job.user_name}</span>
                                ) : ("")}

                                {type === "3" && job.approver_name ? (
                                  <span className="username_cls">{"Approved By: " + job.approver_name}</span>
                                ) : ("")}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div id={"jobDetailsJobId" + job.id} style={{ display: job.isToogleActive === 1 ? "block" : "none"}}>
                          <JobDetails 
                            jobs={jobs}
                            jobDetails={job}
                            handleGetResponse = {handleGetResponse}
                          />
                        </div>
                      </>
                    </div>
                    </section>
                ))
              ) : (
                <div className="alert alert-danger" style={{ display: totals.totalJobs > 0 ? "block" : "none" }}> No Job Found ! </div>
              )}
            </div>
            <Pagination limit={limit} setLimit={setLimit} pageCount={pageCount} setPageCount={setPageCount} totals={totals} /> 
          </>
          }
        </div>
      </section>

    </>
  );
};
export default React.memo(JobTile);

JobTile.propTypes = {
  type: PropTypes.string.isRequired,
};