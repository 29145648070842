import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';

export const Autocomplete = ({question, ...rest}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [isShow, setIsShow] = useState(false);
  const [questionId, setQuestionId] = useState(0);
  const [answer, setAnswer] = useState(question.answer);
  const [isLoadingAnswers, setIsLoadingAnswers] = useState(false);
  // const [showSaveBtn, setShowSaveBtn] = useState(false);

  const saveAnswer = (question_id, answer) =>{
      if(navigator.onLine){
          return fetch(process.env.REACT_APP_API_URL + 'jobs/updatejobanswer/'+question_id, {
              method: 'PUT',
              headers: {
                  'accept': '*/*',
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
              },
              body: JSON.stringify({"answer":answer})
          }).then(data => data.json()).then(result => {
              console.log("saveanswer=",result);
              // setShowSaveBtn(false);
              setIsShow(false);
          });
      }
      else{
          Swal.fire('No Network Connection.');
      }
  }

  const handleSubmitAnswer = async () => {
    // let question_id = 'a';
    await saveAnswer(question.id, answer);
  }

  const handleAnswerFocus = async (question_id) => {
    if(isShow === false){
      setIsLoadingAnswers(true);
      await getAnswerSuggestions(question_id);
    }    
  }

  const getAnswerSuggestions = (question_id) =>{
      if(navigator.onLine){
          return fetch(process.env.REACT_APP_API_URL + 'jobs/answers/list/?question_id='+question_id, {
              method: 'GET',
              headers: {
                  'accept': '*/*',
                  'Content-Type': 'application/json',
                  'token': localStorage.getItem('token')
              },
          }).then(data => data.json()).then(result => {
              setIsLoadingAnswers(false);
              setIsShow(true);
              let response = result.data;
              setQuestionId(response.id);
              if(response["answers"] !== undefined){
                setSuggestions(response.answers);
              }
              else{
                setSuggestions([]);
              }             
          });
      }
      else{
          Swal.fire('No Network Connection.');
      }
  }

  const onChange = async (e, question_id) => {
    // setShowSaveBtn(true);
    setAnswer(e.currentTarget.value)
    await saveAnswer(question_id, e.currentTarget.value)
  };

  const handleBlur = async (e, question_id) => {
    // setShowSaveBtn(true);
    setIsShow(false);
    setAnswer(e.currentTarget.value)
    await saveAnswer(question_id, e.currentTarget.value)  
    
  };

  const handleSelectAnswer = async (e) => {
    setAnswer(e.currentTarget.innerText)
    setIsShow(false);
    setIsLoadingAnswers(false);
    console.log('questionId=',questionId);
    await saveAnswer(questionId, e.currentTarget.innerText)
  };

  const renderAutocomplete = () => {
    
    if (isShow && suggestions.length) {
      return (
        <ul className="autocomplete">
          {suggestions.map((suggestion, index) => {
            // let className;
            // if (index === active) {
            //   className = "active";
            // }
            return (
              <li key={'a'+index} onClick={handleSelectAnswer}>
                {suggestion}
              </li>
            );
          })}
        </ul>
      );
    }
    
    return <></>;
  }
    
  return (
    <>
      <div className="input-group has-validation">
        <input
            type="text"
            autoComplete="off"
            name="answer"
            className="form-control input-md"
            onChange={(e) => onChange(e, question.id)}
            // onKeyDown={onKeyDown}
            value={answer ||''}
            onFocus={ () => handleAnswerFocus(question.id)}
            // onBlur={ (e) => handleBlur(e, question.id) }
          />
          <span className="input-group-text bg-warning" style={{display: isLoadingAnswers === true ? 'block': 'none'}}><i className="bi bi-bootstrap-reboot" ></i></span>
          {/* <span className="input-group-text bg-success text-white" onClick={handleSubmitAnswer} style={{display: showSaveBtn === true ? 'block': 'none', cursor: 'pointer'}} title="Save Answer"><i className="bi bi-save" ></i></span> */}
      </div>
      {renderAutocomplete()} 
    </>
  )
}

export default Autocomplete;

Autocomplete.propTypes = {
  question: PropTypes.object.isRequired
};

