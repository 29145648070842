import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CheckIcon from "@mui/icons-material/Check";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import GavelIcon from "@mui/icons-material/Gavel";
import ReportIcon from "@mui/icons-material/Report";
import QuizIcon from "@mui/icons-material/Quiz";
import StarRateIcon from '@mui/icons-material/StarRate';
import ClearIcon from '@mui/icons-material/Clear';
import AssessmentIcon from '@mui/icons-material/Assessment';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import ReplyIcon from '@mui/icons-material/Reply';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isToggleSidebarActive, setIsToggleSidebarActive] = useState(false);

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const routeRefreash = () => {
    navigate(0);
    handleToggleSidebar();
  };

  const handleToggleSidebar = () => {
    if (isToggleSidebarActive === false) {
      document.body.classList.remove("toggle-sidebar");
      setIsToggleSidebarActive(false);
    } else {
      document.body.classList.add("toggle-sidebar");
      setIsToggleSidebarActive(true);
    }
  };

  return (
    <>
      <aside id="sidebar" className="sidebar">
        <ul className="sidebar-nav" id="sidebar-nav">
          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/dashboard"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/dashboard"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/dashboard"
            >
              <DashboardIcon />
              <span>Dashboard</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/important_jobs"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/important_jobs"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/important_jobs"
            >
              <StarRateIcon />
              <span>Stared Jobs</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/job_accepted"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/job_accepted"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/job_accepted"
            >
              <ThumbUpAltIcon />
              <span>Jobs Accepted</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/job_rejected"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/job_rejected"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/job_rejected"
            >
              <ThumbDownAltIcon />
              <span>Jobs Rejected</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/job_approved"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/job_approved"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/job_approved"
            >
              <CheckIcon />
              <span>Jobs Approved</span>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/job_bid"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/job_bid"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/job_bid"
            >
              <GavelIcon />
              <span>Jobs Bid</span>
            </Link>
          </li>
          
          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/responded_jobs"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/responded_jobs"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/responded_jobs"
            >
              <ReplyIcon />
              <span>Jobs Responded</span>
            </Link>
          </li>

          <li className="nav-item">
            <Link
              onClick={
                location.pathname === "/job_assigned"
                  ? routeRefreash
                  : handleToggleSidebar
              }
              className={
                location.pathname === "/job_assigned"
                  ? "nav-link active"
                  : "nav-link"
              }
              to="/job_assigned"
            >
              <AssignmentReturnIcon />
              <span>Jobs Assigned</span>
            </Link>
          </li>

          {localStorage.getItem("role") === "Admin" ? (
            <>
              <li className="nav-item">
                <Link
                  onClick={
                    location.pathname === "/keyword_list"
                      ? routeRefreash
                      : handleToggleSidebar
                  }
                  className={
                    location.pathname === "/keyword_list"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/keyword_list"
                >
                  <ReportIcon />
                  <span>Manage Keywords</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={
                    location.pathname === "/jobs_by_keyword"
                      ? routeRefreash
                      : handleToggleSidebar
                  }
                  className={
                    location.pathname === "/jobs_by_keyword"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/jobs_by_keyword"
                >
                  <ReportIcon />
                  <span>Jobs by Keyword</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={
                    location.pathname === "/reports"
                      ? routeRefreash
                      : handleToggleSidebar
                  }
                  className={
                    location.pathname === "/reports"
                      ? "nav-link active"
                      : "nav-link"
                  }
                  to="/reports"
                >
                  <AssessmentIcon />
                  <span>Reports</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  onClick={location.pathname === "/settings" ? routeRefreash : handleToggleSidebar}
                  className={`nav-link ${location.pathname === '/settings' ? "active" : ""}`}
                  to="/settings"
                >
                  <SettingsSuggestIcon />
                  <span>Settings</span>
                </Link>
                <ul className="submenu">
                  <li>
                    <Link className={`nav-link ${location.pathname === '/score_prompt' ? "active" : ""}`} to="/score_prompt">
                      <SubdirectoryArrowRightIcon fontSize="small" />
                      <span>Score Prompt</span>
                    </Link>
                  </li>
                  <li>
                    <Link className="nav-link" to="/settings">
                      <SubdirectoryArrowRightIcon fontSize="small" />
                      <span>Others</span>
                    </Link>
                  </li>
                </ul>
              </li>
            </>
          ) : (
            ""
          )}
          {/* <li className="nav-item">
                    <Link onClick={location.pathname === '/question_answers' ? routeRefreash : ''} className={location.pathname === '/question_answers' ? 'nav-link active' : 'nav-link'} to="/question_answers">
                        <QuizIcon />
                        <span>Question Answers</span>
                    </Link>
                </li> */}
          <li className="nav-item">
                    <Link onClick={location.pathname === '/removed_jobs' ? routeRefreash : ''} className={location.pathname === '/removed_jobs' ? 'nav-link active' : 'nav-link'} to="/removed_jobs">
                        <ClearIcon />
                        <span>Removed Jobs</span>
                    </Link>
                </li>
          <li className="nav-item">
            <a className="nav-link" onClick={handleLogout}>
              <i className="bi bi-box-arrow-right"></i>
              <span>Log out</span>
            </a>
          </li>
        </ul>
      </aside>
    </>
  );
}
