import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Swal from 'sweetalert2';
import Autocomplete from "../../Autocomplete";
import { useContext } from 'react';
import AppContext from '../AppContext';
import AssistantIcon from '@mui/icons-material/Assistant';
import { Link } from 'react-router-dom';

export const JobDetails = React.memo(({jobs, jobDetails, handleGetResponse, ...rest}) => {
    const [jobComment, setJobComment] = useState(jobDetails.job_general_comment.split("&apos;").join("'"));
    const myContext = useContext(AppContext);
    const [isCommentSave, setIsCommentSave] = useState(false);
    const [isCopied,setIsCopied] = useState('')

    const textareaRef = useRef(null);
    const hasEventListenerRef = useRef(false);
    console.log("Component rendered");
    const handleGeneralComment = useCallback((jobId, comment) => {
        console.log("saving comment...........................",jobId)
        setJobComment(comment);
        setIsCommentSave(true);
        myContext.emit("comment", JSON.stringify({ jobId, comment }));
    }, [myContext]);

    useEffect(() => {
        // console.log("jobDetails.job_general_comment changed");
        const newComment = jobDetails.job_general_comment.split("&apos;").join("'");
        setJobComment(newComment);
        handleGeneralComment(jobDetails.id, newComment);
    }, [jobDetails.job_general_comment, handleGeneralComment]); // Only run this effect when job_general_comment changes
    
    useEffect(() => {
        if (isCopied) {
            const timer = setTimeout(() => setIsCopied(''), 1000);
            return () => clearTimeout(timer);
        }
    }, [isCopied]);

    useEffect(() => {
        
        if (!hasEventListenerRef.current) {
            console.log("Event listener added");
            myContext.on("viewComment", async (data) => {
                // console.log('view');
                let dataObj = JSON.parse(data);                
                setIsCommentSave(false);
                // console.log(jobDetails)
                let element = document.getElementById('commentJobId'+dataObj.jobId);
                const actionElement = document.getElementById('actionsJobId'+dataObj.jobId);
                if(element === null && dataObj.comment !== ''){
                    element = document.createElement('a');
                    element.classList.add('job-icon', 'comment');
                    element.setAttribute("title", dataObj.comment);
                    element.setAttribute("id", "commentJobId"+dataObj.jobId);
                    element.innerHTML = '<i className="fa fa-comment fa-lg"></i>';
                    actionElement.prepend(element);
                }
                else{
                    if(dataObj.comment == ''){
                        // console.log('remove');
                        if(element !== null){
                            element.remove();
                        }
                    }
                    else{
                        console.log('tooltip');
                        element.setAttribute("title", dataObj.comment);
                    }
                }
            });            
            hasEventListenerRef.current = true;
        }        
    }, []);
    
    useEffect(() => {
        if (isCommentSave) {
            console.log("isCommentSave effect triggered");
            setIsCommentSave(false); // Reset the flag
        }
    }, [isCommentSave]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(jobDetails.snippet)
        setIsCopied('Copied!')
    }

    const renderJobDetailsTable = () => (
        <div className="row">
            <div className="col-md-8">
                <table className="table table-bordered quick-info-table">
                    <tbody>
                        {[
                            { label: 'Category', value: jobDetails.category2 || 'NA' },
                            { label: 'Sub Category', value: jobDetails.subcategory2 || 'NA' },
                            { label: 'Skills', value: jobDetails.skills || 'NA' },
                            { label: 'Job Type', value: jobDetails.job_type || 'NA' },
                            { label: 'Duration', value: jobDetails.duration || 'NA' },
                            { label: 'Member Since', value: jobDetails.client_contract_date || 'NA' },
                        ].map((row, index) => (
                            <tr key={index}>
                                <th>{row.label}</th>
                                <td>{row.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="col-md-4">
                <table className="table table-bordered quick-info-table">
                    <tbody>
                        {[
                            { label: 'Job Posted', value: jobDetails.jobs_posted || '0' },
                            { label: 'Job Hired', value: jobDetails.job_hires || '0' },
                            { label: 'Total $ Spent', value: jobDetails.client_tot_spent || '0' },
                            { label: 'Total Hours Spent', value: (jobDetails.client_tot_hours > 0 ? parseFloat(jobDetails.client_tot_hours).toFixed(2) : '0') },
                            { label: 'Hire Percentage', value: (jobDetails.client_tot_job_posted > 0 && jobDetails.client_tot_job_filled > 0 ? parseFloat((jobDetails.client_tot_job_filled / jobDetails.client_tot_job_posted) * 100).toFixed(2) : '0') },
                            { label: 'Average Hourly Rate', value: jobDetails.workload || 'NA' },
                            { label: 'Total Interviewing', value: jobDetails.op_tot_intv || 0 },
                            { label: 'Total Proposals', value: jobDetails.op_tot_cand || 0 },
                        ].map((row, index) => (
                            <tr key={"body_"+index}>
                                <th>{row.label}</th>
                                <td>{row.value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
    return (
        <>
        <div className="job-content">
            <h4>Job Requirements</h4>
            <div className='row d-flex align-items-center justify-content-center'>
                <div className='col-md-1 text-center alert-success'>{isCopied}</div>
            </div>
            <p className="mb-3" onDoubleClick={copyToClipboard} style={{ whiteSpace: 'pre-line' }}>
                {
                    jobDetails.snippet !== '' 
                    ? jobDetails.snippet 
                    : ''
                } <br />
                {
                    jobDetails.url !== '' 
                    ? <a className="link-text" target="_blank" href={jobDetails.url}>{jobDetails.url}</a> 
                    : 'DDDD' 
                }  
                <a target="_blank" href={"https://upwork.com/jobs/"+jobDetails.job_id} >{"https://upwork.com/jobs/"+jobDetails.job_id}</a>
            </p>
          
            <h4>Job Details</h4>
            <div className="form-group">
                <label htmlFor="exampleFormControlTextarea1"><h6>General Comment Of Job</h6></label>
                <textarea 
                    ref={textareaRef} 
                    name="job_general_comment" 
                    className={'form-control commentTextAreaJobId'+jobDetails.id} 
                    id="exampleFormControlTextarea1" rows="8" value={jobComment} 
                    onChange={(e) => { handleGeneralComment(jobDetails.id, e.target.value) }}
                ></textarea>
            </div>

            {jobDetails.questions && jobDetails.questions.length > 0 ? (
                <div className="row">
                    <div className="col-md-12">
                        <table className="table table-bordered quick-info-table"> 
                            <thead  id={jobDetails.id}>
                                <tr key={"Head"+jobDetails.id}>
                                    <th style={{width: "5%"}}>S. No.</th>
                                    <th style={{width: "95%"}}>Question</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobDetails.questions.map( (question,index) => {
                                    return (
                                        <React.Fragment key={`Details_${index}`}>
                                            <tr>
                                                <th>{index+1}</th>
                                                <th>{question.question} 
                                                <span>
                                                    {question.answer ? '' : 
                                                    <Link to={"/question_details?question_id="+question.id+"&question="+question.question} target="_Blank"> <AssistantIcon/> </Link>}
                                                    </span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th style={{verticalAlign: "top"}}>Ans.</th>
                                                <td>
                                                    <Autocomplete question={question}/>
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                })}                                
                            </tbody>
                        </table>
                    </div>
                </div>

            ): ""}
            {renderJobDetailsTable()}
        </div>
        </>
    );
});

export default JobDetails;

JobDetails.propTypes = {
    jobDetails: PropTypes.object.isRequired
};