
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect } from 'react';
import { Layout } from '../Layout/Layout';

const validationSchema = yup.object({
    prompt: yup
        .string()
        .required('This field is required!')
});
export default function ScorePrompt() {
    useEffect(() => {
        getPrompt();
    }, []);

    const getPrompt = async () => {
        return fetch(process.env.REACT_APP_API_URL + `jobs/getScorePrompt/prompt`, {
            method: 'GET',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
        }).then((data) => data.json().then((response) => {
            console.log("response0000----------------------------", response);
            if (response.success) {
                formik.setFieldValue('id', response.data?.id)
                formik.setFieldValue('prompt', response.data?.prompt)
            }
            else {
                console.log("error");
            }
        }).catch((err) => {
            console.log(err);
        })
        );
    }

    const initialValues = {
        id: '',
        prompt: '',
    }
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            handleSave(values);
        }
    });

    const handleSave = (postData) => {
        console.log("postData", postData)
        // return;
        return fetch(process.env.REACT_APP_API_URL + 'jobs/updateScorePrompt', {
            method: 'POST',
            headers: {
                'accept': '*/*',
                'Content-Type': 'application/json',
                'token': localStorage.getItem('token')
            },
            body: JSON.stringify(postData)
        }).then(data => data.json()).then(res => {
            if (res.success) {
                Swal.fire(res.message, '', 'success');
            }
            else {
                Swal.fire(res.message, '', 'error')
            }
        })
            .catch((error) => {
                Swal.fire(error, '', 'error')
            });
    }

    return (
        <Layout>
            <section className="section profile">
                <div className='row align-items-center'>
                    <div className='col-lg-12 col-md-12 col-sm-12'>
                        <h1 className='main-heading'>Score Prompt</h1>
                    </div>                    
                </div>

                <div className='job-detls' style={{ background: '#fff' }}>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="card">
                                <p className="text-danger" style={{fontSize:'11px', textAlign:'right'}}>*This prompt is used to score a job and stored in database.</p>
                                <div className="card-body pt-3">

                                    <form onSubmit={formik.handleSubmit}>
                                        {console.log("errorrr", formik.errors)}
                                        <textarea
                                            name="prompt"
                                            className={'form-control h-100 mb-3'}
                                            rows="20" cols="50"
                                            onChange={formik.handleChange}
                                            value={formik.values.prompt}></textarea>
                                        {formik.touched.prompt && formik.errors.prompt ? <div className="text-danger">{formik.errors.prompt}</div> : null}
                                        <div className="text-right">
                                            <button type="submit" className="r-btn r-btn-primary">Save</button>
                                        </div>
                                    </form>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}