import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard/Dashboard";
import JobAccepted from "./components/JobAccepted/JobAccepted";
import JobApproved from "./components/JobApproved/JobApproved";
import JobAssigned from "./components/JobAssigned/JobAssigned";
import JobBid from "./components/JobBid/JobBid";
import JobRejected from "./components/JobRejected/JobRejected";
import Login from "./components/Login/Login";
import Report from "./components/Report/Report";
import QuestionAnswers from "./components/QuestionAnswers/QuestionAnswers";
import QuestionDetails from "./components/JobDetails/QuestionDetails";
import PublicRoutes from "./components/Routes/PublicRoutes";
import ProtectedRoutes from "./components/Routes/ProtectedRoutes";
import UserProfile from "./components/Profile/UserProfile";
import ChangePassword from "./components/Profile/ChangePassword";
import ForgotPassword from "./components/Login/ForgotPassword";
import KeywordList from "./components/KeywordList/KeywordList";
import JobsByKeyword from "./components/JobsByKeyword/JobsByKeyword";
// import JobsByKeyword from "./components/JobsByKeyword/JobsByKeyword";
import JobStared from "./components/JobStared/JobStared";
import Settings from "./components/Settings/Settings";
import JobResponded from "./components/JobResponded/JobResponded";
import JobRemoved from "./components/JobRemoved/JobRemoved";
import ScorePrompt from "./components/Settings/ScorePrompt";

function App() {
  return (
    <Routes>
      <Route path="login" element={<PublicRoutes />}>
        <Route path="/login" element={<Login />} />
      </Route>
      <Route path="forgot_password" element={<PublicRoutes />}>
        <Route path="/forgot_password" element={<ForgotPassword />} />
      </Route>

      <Route path="/" element={<ProtectedRoutes />}>
        <Route path="/" element={<Navigate replace to="dashboard" />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="important_jobs" element={<JobStared />} />
        <Route path="job_accepted" element={<JobAccepted />} />
        <Route path="job_rejected" element={<JobRejected />} />
        <Route path="job_approved" element={<JobApproved />} />
        <Route path="job_bid" element={<JobBid />} />
        <Route path="job_assigned" element={<JobAssigned />} />
        <Route path="reports" element={<Report />} />
        <Route path="question_answers" element={<QuestionAnswers />} />
        <Route path="question_details" element={<QuestionDetails />} />
        <Route path="user_profile" element={<UserProfile />} />
        <Route path="change_password" element={<ChangePassword />} />
        <Route path="keyword_list" element={<KeywordList />} />
        <Route path="jobs_by_keyword" element={<JobsByKeyword />} />
        <Route path="settings" element={<Settings />} />        
        <Route path="responded_jobs" element={<JobResponded />} />
        <Route path="removed_jobs" element={<JobRemoved />} />
        <Route path="score_prompt" element={<ScorePrompt />} />

      </Route>
    </Routes>
  );
}

export default App;
